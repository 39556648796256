import React, { useState } from 'react';
import styled from 'styled-components';
import { LayoutBody } from 'components/ReusableComponents/Layout/LayoutBody';
import { darkScrollbar, lightScrollbar } from 'components/ReusableComponents/Scrollbar/Scrollbar';
import InsightsFilter from './components/InsightsFilter';
import InsightsMetricGraph from './components/InsightsMetrics';
import InsightsPriceRevenue from './components/InsightsPriceRevenue';
import { now } from 'constants/GlobalConstant';
import { useSelector } from 'react-redux';
import InsightsProperties from './components/InsightsProperties';
import ReservationsandBookings from './components/ReservationsandBookings';



const InsightsBody = styled(LayoutBody)`  
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  height: calc(100vh - 72px);
  gap: 16px;
  ${({ theme }) => (theme.isDefault ? lightScrollbar : darkScrollbar)};
`;

const nowDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);

const Insights = () => {
  const [monthYear, setMonthYear] = useState(nowDate);
  const { users } = useSelector(({ users }) => ({users}));

  const {dashboardDetail, dashboardDetailsLoaded} = users;
  
  const currentYear = Object.keys(dashboardDetail).length > 0 && dashboardDetail["currentYear"] ? dashboardDetail["currentYear"] : [];
  const  lastYear = Object.keys(dashboardDetail).length > 0 && dashboardDetail["lastYear"] ? dashboardDetail["lastYear"] : [];
  const lastYearChartOCCADR = Object.keys(dashboardDetail).length > 0 && dashboardDetail["lastYearChartOCCADR"] ? dashboardDetail["lastYearChartOCCADR"] : [];
  const currentYearChartOCCADR = Object.keys(dashboardDetail).length > 0 && dashboardDetail["currentYearChartOCCADR"] ? dashboardDetail["currentYearChartOCCADR"] : [];
  const totalRecordsInCurrentYear = currentYearChartOCCADR.length;

  const currentId = currentYearChartOCCADR[0] ? currentYearChartOCCADR[totalRecordsInCurrentYear - 1]["currentId"]: 0;

  const currentId15 = currentYearChartOCCADR[totalRecordsInCurrentYear - 16] ? currentYearChartOCCADR[totalRecordsInCurrentYear - 16]["currentId"]: 0;
  const currentId30 = currentYearChartOCCADR[totalRecordsInCurrentYear - 31] ? currentYearChartOCCADR[totalRecordsInCurrentYear - 31]["currentId"]: 0;
  const currentId60 = currentYearChartOCCADR[totalRecordsInCurrentYear - 61] ? currentYearChartOCCADR[totalRecordsInCurrentYear - 61]["currentId"]: 0;
  const currentId90 = currentYearChartOCCADR[totalRecordsInCurrentYear - 91] ? currentYearChartOCCADR[totalRecordsInCurrentYear - 91]["currentId"]: 0;

  const currentTotalRent = currentYearChartOCCADR[0]? currentYearChartOCCADR[totalRecordsInCurrentYear - 1]?.["currentRent"]?.toFixed(0) : 0;
  const currentTotalAncillaryRevenue = currentYearChartOCCADR[0] ? currentYearChartOCCADR[totalRecordsInCurrentYear - 1]["currentAncillary"]?.toFixed(0) : 0;
  const currentOccupancy = dashboardDetail["currentSupplyAndDemandBehavior"]?.length &&  dashboardDetail["currentSupplyAndDemandBehavior"][0] ? dashboardDetail["currentSupplyAndDemandBehavior"][totalRecordsInCurrentYear - 1]?.["currentOccupancy"]?.toFixed(0) : 0;
  const currentAdr = currentYearChartOCCADR[0] ? currentYearChartOCCADR[totalRecordsInCurrentYear - 1]?.["currentADR"]?.toFixed(0) : 0;
  const currentREVPAR = currentYearChartOCCADR[0] ? currentYearChartOCCADR[totalRecordsInCurrentYear - 1]?.["currentREVPAR"]?.toFixed(0) : 0;
  const currentTotalNights = dashboardDetail["availableNights"];
  const currentRev = currentYearChartOCCADR[0] ? currentYearChartOCCADR[totalRecordsInCurrentYear - 1]?.["currentRevenue"]?.toFixed(0) : 0;

  const currentOccupancy15 = dashboardDetail["currentSupplyAndDemandBehavior"]?.length &&  dashboardDetail["currentSupplyAndDemandBehavior"][0] ? dashboardDetail["currentSupplyAndDemandBehavior"][totalRecordsInCurrentYear - 16]?.["currentOccupancy"]?.toFixed(0) : 0;
  const currentOccupancy30 = dashboardDetail["currentSupplyAndDemandBehavior"]?.length &&  dashboardDetail["currentSupplyAndDemandBehavior"][0] ? dashboardDetail["currentSupplyAndDemandBehavior"][totalRecordsInCurrentYear - 31]?.["currentOccupancy"]?.toFixed(0) : 0;
  const currentOccupancy60 = dashboardDetail["currentSupplyAndDemandBehavior"]?.length &&  dashboardDetail["currentSupplyAndDemandBehavior"][0] ? dashboardDetail["currentSupplyAndDemandBehavior"][totalRecordsInCurrentYear - 61]?.["currentOccupancy"]?.toFixed(0) : 0;
  const currentOccupancy90 = dashboardDetail["currentSupplyAndDemandBehavior"]?.length &&  dashboardDetail["currentSupplyAndDemandBehavior"][0] ? dashboardDetail["currentSupplyAndDemandBehavior"][totalRecordsInCurrentYear - 91]?.["currentOccupancy"]?.toFixed(0) : 0;

  const currentTotalRent15 = currentYearChartOCCADR[totalRecordsInCurrentYear - 16]? currentYearChartOCCADR[totalRecordsInCurrentYear - 16]?.["currentRent"]?.toFixed(0) : 0;
  const currentTotalRent30 = currentYearChartOCCADR[totalRecordsInCurrentYear - 31]? currentYearChartOCCADR[totalRecordsInCurrentYear - 31]?.["currentRent"]?.toFixed(0) : 0;
  const currentTotalRent60 = currentYearChartOCCADR[totalRecordsInCurrentYear - 61]? currentYearChartOCCADR[totalRecordsInCurrentYear - 61]?.["currentRent"]?.toFixed(0) : 0;
  const currentTotalRent90 = currentYearChartOCCADR[totalRecordsInCurrentYear - 91]? currentYearChartOCCADR[totalRecordsInCurrentYear - 91]?.["currentRent"]?.toFixed(0) : 0;

  const currentREVPAR15 = currentYearChartOCCADR[totalRecordsInCurrentYear - 16] ? currentYearChartOCCADR[totalRecordsInCurrentYear - 16]?.["currentREVPAR"]?.toFixed(0) : 0;
  const currentREVPAR30 = currentYearChartOCCADR[totalRecordsInCurrentYear - 31] ? currentYearChartOCCADR[totalRecordsInCurrentYear - 31]?.["currentREVPAR"]?.toFixed(0) : 0;
  const currentREVPAR60 = currentYearChartOCCADR[totalRecordsInCurrentYear - 61] ? currentYearChartOCCADR[totalRecordsInCurrentYear - 61]?.["currentREVPAR"]?.toFixed(0) : 0;
  const currentREVPAR90 = currentYearChartOCCADR[totalRecordsInCurrentYear - 91] ? currentYearChartOCCADR[totalRecordsInCurrentYear - 91]?.["currentREVPAR"]?.toFixed(0) : 0;

  const currentRev15 = currentYearChartOCCADR[totalRecordsInCurrentYear - 16] ? currentYearChartOCCADR[totalRecordsInCurrentYear - 16]?.["currentRevenue"]?.toFixed(0) : 0;
  const currentRev30 = currentYearChartOCCADR[totalRecordsInCurrentYear - 31] ? currentYearChartOCCADR[totalRecordsInCurrentYear - 31]?.["currentRevenue"]?.toFixed(0) : 0;
  const currentRev60 = currentYearChartOCCADR[totalRecordsInCurrentYear - 61] ? currentYearChartOCCADR[totalRecordsInCurrentYear - 61]?.["currentRevenue"]?.toFixed(0) : 0;
  const currentRev90 = currentYearChartOCCADR[totalRecordsInCurrentYear - 91] ? currentYearChartOCCADR[totalRecordsInCurrentYear - 91]?.["currentRevenue"]?.toFixed(0) : 0;
  
  let lastYearIdArray = [];
  lastYearChartOCCADR.forEach((records) => {
    lastYearIdArray.push(Number(records.lastId));
  });

  let closest;
  let closest15;
  let closest30;
  let closest60;
  let closest90;

  if (lastYearIdArray.length > 0) {
    closest = lastYearIdArray.reduce((a, b) => {
      return Math.abs(b - currentId) < Math.abs(a - currentId) ? b : a;
    });
  }
  
  if (lastYearIdArray.length > 0) {
    closest15 = lastYearIdArray.reduce((a, b) => {
      return Math.abs(b - currentId15) < Math.abs(a - currentId15) ? b : a;
    });
  }

  if (lastYearIdArray.length > 0) {
    closest30 = lastYearIdArray.reduce((a, b) => {
      return Math.abs(b - currentId30) < Math.abs(a - currentId30) ? b : a;
    });
  }

  if (lastYearIdArray.length > 0) {
    closest60 = lastYearIdArray.reduce((a, b) => {
      return Math.abs(b - currentId60) < Math.abs(a - currentId60) ? b : a;
    });
  }

  if (lastYearIdArray.length > 0) {
    closest90 = lastYearIdArray.reduce((a, b) => {
      return Math.abs(b - currentId90) < Math.abs(a - currentId90) ? b : a;
    });
  }

  const closestDays = lastYearChartOCCADR.filter((record) =>  record.lastId === closest);
  const closestDays15 = lastYearChartOCCADR.filter((record) =>  record.lastId === closest15);
  const closestDays30 = lastYearChartOCCADR.filter((record) =>  record.lastId === closest30);
  const closestDays60 = lastYearChartOCCADR.filter((record) =>  record.lastId === closest60);
  const closestDays90 = lastYearChartOCCADR.filter((record) =>  record.lastId === closest90);

  const currentRent = currentYearChartOCCADR[0] ? currentYearChartOCCADR[totalRecordsInCurrentYear - 1]?.["currentRent"].toFixed(0): 0;
  const lastRent = (closestDays[0] && closestDays[0]["lastRent"]) ? closestDays[0]?.["lastRent"].toFixed(0) : 0;
  const lastREVPAR = (closestDays[0] && closestDays[0]["lastREVPAR"]) ? closestDays[0]?.["lastREVPAR"] : 0;


  const currentAncillary = currentYearChartOCCADR[0] ? currentYearChartOCCADR[totalRecordsInCurrentYear - 1]?.["currentAncillary"].toFixed(0): 0;
  const lastYearAncillary = (closestDays[0] && closestDays[0]["lastAncillary"]) ? closestDays[0]?.["lastAncillary"].toFixed(0): 0;
  const lastOccupancy     = (closestDays[0] && closestDays[0]["lastOccupancy"]) ? closestDays[0]?.["lastOccupancy"].toFixed(0) : 0;
  const lastAvgDailyRate  = (closestDays[0] && closestDays[0]["lastADR"]) ? closestDays[0]?.["lastADR"].toFixed(0) : 0;

  const totalRentDiff = Number(currentRent) - Number(lastRent);
  const ancillaryDiff = Number(currentAncillary) - Number(lastYearAncillary);
  const occupancyDiff = currentOccupancy- lastOccupancy;
  const adrDiff = Number(currentAdr) - Number(lastAvgDailyRate);

  const lastOccupancy15 = (closestDays15[0] && closestDays15[0]["lastOccupancy"]) ? closestDays15[0]?.["lastOccupancy"].toFixed(0) : 0;
  const lastOccupancy30 = (closestDays30[0] && closestDays30[0]["lastOccupancy"]) ? closestDays30[0]?.["lastOccupancy"].toFixed(0) : 0;
  const lastOccupancy60 = (closestDays60[0] && closestDays60[0]["lastOccupancy"]) ? closestDays60[0]?.["lastOccupancy"].toFixed(0) : 0;
  const lastOccupancy90 = (closestDays90[0] && closestDays90[0]["lastOccupancy"]) ? closestDays90[0]?.["lastOccupancy"].toFixed(0) : 0;

  const lastREVPAR15 = (closestDays15[0] && closestDays15[0]["lastREVPAR"]) ? closestDays15[0]?.["lastREVPAR"] : 0;
  const lastREVPAR30 = (closestDays30[0] && closestDays30[0]["lastREVPAR"]) ? closestDays30[0]?.["lastREVPAR"] : 0;
  const lastREVPAR60 = (closestDays60[0] && closestDays60[0]["lastREVPAR"]) ? closestDays60[0]?.["lastREVPAR"] : 0;
  const lastREVPAR90 = (closestDays90[0] && closestDays90[0]["lastREVPAR"]) ? closestDays90[0]?.["lastREVPAR"] : 0;

  let dataDiff = [];

  if (currentYear.length > 0) {
    currentYear.forEach((element, index) => {
      let currentId = element.currentId ? element.currentId : 0;
      if (lastYear.length > 0 && lastYear[index]["monthData"] && lastYear[index]["monthData"].length > 0) {
        let lastYearIdArray = [];

        lastYear[index]["monthData"].forEach((records, index) => {
          lastYearIdArray.push(Number(records.lastId));
        });

        if (lastYearIdArray.length > 0) {
          closest = lastYearIdArray.reduce((a, b) => {
            return Math.abs(b - currentId) < Math.abs(a - currentId) ? b : a;
          });
        }

        let currentADR = element.currentADR ? element.currentADR.toFixed(0) : 0;
        let currentRent = element.currentRent
          ? element.currentRent.toFixed(0)
          : 0;
        let currentOccupancy = element.currentOccupancy
          ? element.currentOccupancy.toFixed(0)
          : 0;
        let currentAncillary = element.currentAncillary
          ? element.currentAncillary.toFixed(0)
          : 0;

        let adrDiff = Number(currentADR);
        let rentDiff = Number(currentRent);
        let ancillaryDiff = Number(currentAncillary);
        let occupancyDiff = Number(currentOccupancy);


        dataDiff[index] = {
          'name': element.name,
          'ADR': adrDiff || 0,
          'Occupancy': occupancyDiff || 0,
          "Ancillary Revenue": ancillaryDiff || 0,
          "Total Rent": rentDiff || 0,
        };
      }
    });
  };


  const valueData = {
    totalRent: {
      current: currentTotalRent,
      diff: totalRentDiff,
    },
    ancillary: {
      current: currentTotalAncillaryRevenue,
      diff: ancillaryDiff,
    },
    occupancy: {
      current: currentOccupancy,
      diff: occupancyDiff,
    },
    adr: {
      current: currentAdr,
      diff: adrDiff,
    }
  };

// insights revenue

let lastSupplyAndDemandCount    = 0;
let currentSupplyAndDemandCount = 0;
let lastSupplyAndDemandOcc      = [];
let currentSupplyAndDemandOcc   = [];
let lastSupplyAndDemandADR      = [];
let currentSupplyAndDemandADR   = [];
let nearestForecastOccupancy = 0;
let nearestForecastADR = 0;
let nearestForecastRent = 0;
let nearestForecastRevenue = 0;
let nearestForecastREVPAR = 0;

if (Object.keys(dashboardDetail).length > 0 && dashboardDetail["lastSupplyAndDemandBehavior"]) {
  dashboardDetail["lastSupplyAndDemandBehavior"]?.forEach((element) => {
    if (element.lastId && element.lastId < 365) {
      // lastSupplyAndDemandUpdatedData[lastSupplyAndDemandCount] = element
      lastSupplyAndDemandOcc[lastSupplyAndDemandCount] = {
        primary: element.lastId,
        secondary: element.lastOccupancy ? element.lastOccupancy : "0",
      };
      lastSupplyAndDemandADR[lastSupplyAndDemandCount] = {
        primary: element.lastId,
        secondary: element.lastADR ? element.lastADR.toFixed(0) : "0",
      };
      lastSupplyAndDemandCount++;
    }
  });

  dashboardDetail["currentSupplyAndDemandBehavior"]?.forEach((element) => {
    if (element.currentId && element.currentId < 365) {
      // currentSupplyAndDemandUpdatedData[currentSupplyAndDemandCount] = element
      currentSupplyAndDemandOcc[currentSupplyAndDemandCount] = {
        primary: element.currentId,
        secondary: element.currentOccupancy ? element.currentOccupancy : "0",
      };
      currentSupplyAndDemandADR[currentSupplyAndDemandCount] = {
        primary: element.currentId,
        secondary: element.currentADR ? element.currentADR : "0",
      };
      currentSupplyAndDemandCount++;
    }
  });

  const totalRecordsInLastYear = lastYearChartOCCADR.length;
  const lastOcc = dashboardDetail["lastSupplyAndDemandBehavior"][totalRecordsInLastYear - 1] ? dashboardDetail["lastSupplyAndDemandBehavior"][totalRecordsInLastYear - 1]["lastOccupancy"].toFixed(0) : 0;
  const lastYearREVPAR = lastYearChartOCCADR[totalRecordsInLastYear - 1] ? lastYearChartOCCADR[totalRecordsInLastYear - 1]["lastREVPAR"].toFixed(0) : 0;

  const forecast15 = Number(currentOccupancy15) + Number(lastOcc) - Number(lastOccupancy15);
  const forecast30 = Number(currentOccupancy30) + Number(lastOcc) - Number(lastOccupancy30);
  const forecast60 = Number(currentOccupancy60) + Number(lastOcc) - Number(lastOccupancy60);
  const forecast90 = Number(currentOccupancy90) + Number(lastOcc) - Number(lastOccupancy90);

  nearestForecastOccupancy = Number(currentOccupancy) + Number(lastOcc - lastOccupancy);
  nearestForecastREVPAR = Number(currentREVPAR) + Number(lastYearREVPAR - lastREVPAR);

  const forecastREVPAR15 = Number(currentREVPAR15) + Number(lastYearREVPAR) - Number(lastREVPAR15);
  const forecastREVPAR30 = Number(currentREVPAR30) + Number(lastYearREVPAR) - Number(lastREVPAR30);
  const forecastREVPAR60 = Number(currentREVPAR60) + Number(lastYearREVPAR) - Number(lastREVPAR60);
  const forecastREVPAR90 = Number(currentREVPAR90) + Number(lastYearREVPAR) - Number(lastREVPAR90);  

  nearestForecastADR = nearestForecastREVPAR / (nearestForecastOccupancy / 100);

  nearestForecastRent = nearestForecastREVPAR * Number(currentTotalNights);
  nearestForecastRevenue = (Number(nearestForecastRent)/Number(currentRent)) * Number(currentRev);

  const forecastRent15 = forecastREVPAR15 * Number(currentTotalNights);
  const forecastRevenue15 = currentTotalRent15 > 0 ? (Number(forecastRent15)/Number(currentTotalRent15)) * Number(currentRev15) : 0;

  const forecastRent30 = forecastREVPAR30 * Number(currentTotalNights);
  const forecastRevenue30 = currentTotalRent30 > 0 ? (Number(forecastRent30)/Number(currentTotalRent30)) * Number(currentRev30) : 0;

  const forecastRent60 = forecastREVPAR60 * Number(currentTotalNights);
  const forecastRevenue60 = currentTotalRent60 > 0 ? (Number(forecastRent60)/Number(currentTotalRent60)) * Number(currentRev60) : 0;

  const forecastRent90 = forecastREVPAR90 * Number(currentTotalNights);
  const forecastRevenue90 = currentTotalRent90 > 0 ? (Number(forecastRent90)/Number(currentTotalRent90)) * Number(currentRev90) : 0;

  const mpe15 = {
    revpar: nearestForecastREVPAR > 0 ? (forecastREVPAR15 - nearestForecastREVPAR) / nearestForecastREVPAR : 0, 
    rent: nearestForecastRent ? (forecastRent15 - nearestForecastRent) / nearestForecastRent : 0,
    revenue: nearestForecastRevenue ? (forecastRevenue15 - nearestForecastRevenue) / nearestForecastRevenue : 0,
  };
  const mpe30 = {
    revpar: nearestForecastREVPAR > 0 ? (forecastREVPAR30 - nearestForecastREVPAR) / nearestForecastREVPAR : 0,
    rent: nearestForecastRent ? (forecastRent30 - nearestForecastRent) / nearestForecastRent : 0,
    revenue: nearestForecastRevenue ? (forecastRevenue30 - nearestForecastRevenue) / nearestForecastRevenue : 0,
  };
  const mpe60 = {
    revpar: nearestForecastREVPAR > 0 ? (forecastREVPAR60 - nearestForecastREVPAR) / nearestForecastREVPAR : 0,
    rent: nearestForecastRent ? (forecastRent60 - nearestForecastRent) / nearestForecastRent : 0,
    revenue: nearestForecastRevenue ? (forecastRevenue60 - nearestForecastRevenue) / nearestForecastRevenue : 0,
  };
  const mpe90 = {
    revpar: nearestForecastREVPAR > 0 ? (forecastREVPAR90 - nearestForecastREVPAR) / nearestForecastREVPAR : 0,
    rent: nearestForecastRent ? (forecastRent90 - nearestForecastRent) / nearestForecastRent : 0,
    revenue: nearestForecastRevenue ? (forecastRevenue90 - nearestForecastRevenue) / nearestForecastRevenue : 0,
  };
};

const priceRevData = [
  {
    label: "Current Occupancy",
    data: currentSupplyAndDemandOcc,
    secondaryAxisID: "Occupancy",
    color: "#2ECADB",
  },
  {
    label: "Last Occupancy",
    data: lastSupplyAndDemandOcc,
    secondaryAxisID: "Occupancy",
    color: "#9747FF",
  },
  
  {
    label: "Current ADR",
    data: currentSupplyAndDemandADR,
    secondaryAxisID: "ADR",
    color: "#FFC805",
  },
  {
    label: "Last ADR",
    data: lastSupplyAndDemandADR,
    secondaryAxisID: "ADR",
    color: "#C6A1F5",
  },
];

const forecastPriceRev = [{
  label: "ADR Forecast",
  isForecast: true,
  data: [
    {
      primary: 0.1,
      secondary: nearestForecastADR > 0 ? nearestForecastADR : 10,
      radius: 5,
    },
  ],
  secondaryAxisID: "ADR",
  color: "#FFC805"
},
{
  label: "Occupancy Forecast",
  isForecast: true,
  data: [
    {
      primary: 0.1,
      secondary: nearestForecastOccupancy > 0 ? nearestForecastOccupancy : 10,
      radius: 5,
    }
  ],
  secondaryAxisID: "Occupancy",
  color: "#2ECADB",
},
]



//revenue metrics

let lCount = 0, cCount = 0;
let lastRevenue = [];
let currentRevenue = [];
let lastREV = [];
let currentREV = [];
let lastRentChart = [];
let currentRentChart = [];

if (Object.keys(dashboardDetail).length > 0 && dashboardDetail["lastYearChartOCCADR"]) {
  dashboardDetail["lastYearChartOCCADR"].forEach((element) => {
    if (element.lastId && element.lastId < 365) {
      lastRevenue[lCount] = {
        primary: element.lastId,
        secondary: element.lastRevenue ? element.lastRevenue.toFixed(0) : "0",
      };
      lastRentChart[lCount] = {
        primary: element.lastId,
        secondary: element.lastRent ? element.lastRent.toFixed(0) : "0",
      };
      lastREV[lCount] = {
        primary: element.lastId,
        secondary: element.lastREVPAR ? element.lastREVPAR.toFixed(0) : "0",
      };
      lCount++;
    }
  });

  dashboardDetail["currentYearChartOCCADR"].forEach((element) => {
    if (element.currentId && element.currentId < 365) {
      currentRevenue[cCount] = {
        primary: element.currentId,
        secondary: element.currentRevenue
          ? element.currentRevenue.toFixed(0)
          : "0",
      };
      currentRentChart[cCount] = {
        primary: element.currentId,
        secondary: element.currentRent ? element.currentRent.toFixed(0) : "0",
      };
      currentREV[cCount] = {
        primary: element.currentId,
        secondary: element.currentREVPAR
          ? element.currentREVPAR.toFixed(0)
          : "0",
      };
      cCount++;
    }
  });
}


const revenueMetricsData = {
  revenueChart : [
    {
      label: "Current Revenue",
      data: currentRevenue,
      secondaryAxisID: "currentRevenue",
      color: "#2ECADB",
    },
    {
      label: "Last Revenue",
      data: lastRevenue,
      secondaryAxisID: "lastRevenue",
      color: "#C6A1F5",
    },
  ],
  rentChart: [
    {
      label:  "Current Rent",
      data:  currentRentChart,
      secondaryAxisID: "currentRent",
      color: "#2ECADB",
    },
    {
      label: "Last Rent",
      data: lastRentChart,
      secondaryAxisID: "lastRent",
      color: "#C6A1F5",
    },
  ],
  revparChart: [
    {
      label: "Current REVPAR",
      data:  currentREV,
      secondaryAxisID: "currentRevPar",
      color: "#2ECADB",
    },
    {
      label: "Last REVPAR",
      data: lastREV,
      secondaryAxisID: "lastRevPar",
      color: "#C6A1F5"
    },
  ]
};



//add forecast 
if (monthYear >= nowDate) {
  priceRevData.push(...forecastPriceRev)
  revenueMetricsData.revenueChart.push({
    label: "Forecast",
    isForecast: true,
    data: [
      {
        primary: 0.1,
        secondary: nearestForecastRevenue,
        radius: 5,
      },
    ],
    secondaryAxisID: "currentRevenue",
    color: "#2ECADB",
  },)
  revenueMetricsData.rentChart.push({
    label: "Forecast",
    isForecast: true,
    data: [
      {
        primary: 0.1,
        secondary: nearestForecastRent,
        radius: 5,
      },
    ],
    secondaryAxisID: "currentRent",
    color: "#2ECADB",
  },)
  revenueMetricsData.revparChart.push({
    label: "Forecast",
    isForecast: true,
    data: [
      {
        primary: 0.1,
        secondary: nearestForecastREVPAR,
        radius: 5,
      },
    ],
    secondaryAxisID: "currentRevPar",
    color: "#2ECADB"
  },)
};


// insights properties data
const InsightsPropsData = {
  inventory:  dashboardDetail["inventoryGrowth"],
  bookings: String(dashboardDetail["totalReservations"]).replace(/\B(?=(\d{3})+(?!\d))/g,","),
  activeProp: dashboardDetail["activeProperties"],
  bookedNights: String(dashboardDetail["bookedNights"]).replace(/\B(?=(\d{3})+(?!\d))/g,",")
}


return (
  <InsightsBody>
    <InsightsFilter monthYear={monthYear} setMonthYear={setMonthYear}/>
    <InsightsMetricGraph chartData={dataDiff} valueData={valueData} isLoading={!dashboardDetailsLoaded}/>
    <InsightsPriceRevenue priceRevData={priceRevData} revenueMetricsData={revenueMetricsData} isLoading={!dashboardDetailsLoaded} monthYear={monthYear}/>
    <InsightsProperties  InsightsPropsData={InsightsPropsData} isLoading={!dashboardDetailsLoaded}/>
    <ReservationsandBookings pieData={dashboardDetail["reservationPerSource"]} recentBookingData={dashboardDetail["recentActivity"]} monthYear={monthYear} isLoading={!dashboardDetailsLoaded}/>
  </InsightsBody>
)
}


export default Insights;