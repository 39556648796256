import React, { Fragment, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { BodyCSS } from '../../../components/ReusableComponents/Card-Body/BodyCSS';
import { BodyText, BodyTitle } from '../../../components/ReusableComponents/Text/Text';
import FormItemInput from 'components/ReusableComponents/Form/FormItemInput';
import info from 'assets/images/generic/info.svg';
import IncrementDecrementInput from '../../priceAdjustment/Optimization/components/subComponents/OptimizeDrawer/IncrementDecrementInput';
import { PrimaryButton } from '../../../components/ReusableComponents/Button/Button';
import { markets } from '../../../services/markets';
import { Select, Tooltip, message } from 'antd';
import { useDispatch } from 'react-redux';
import { getListings } from '../../../appRedux/actions';
import { darkScrollbar, lightScrollbar } from 'components/ReusableComponents/Scrollbar/Scrollbar';
import { media } from '../../../styles/stylesheet';
import CurrencyFlag from '../../../components/ReusableComponents/CurrencyFlag/CurrencyFlag';
import { user } from '../../../services/user';


const ListingDrawerDiv = styled.div`
  ${BodyCSS}
  width: ${({drawerWidth}) => drawerWidth};
  min-height: 100vh;
  height: 100vh;
  position: fixed;
  z-index: 50;
  top: 0px;
  right: 0px;
  transition: all 0.25s linear;


`;

const ListingDrawerBody = styled.div` 
  width: 100%;
  // min-height: 1080px;
  height: 100vh;

  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
`;

const CloseButton = styled.button`
  width: fit-content: 
  height: fit-content;
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
`;

const TitleDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 16px;
`;

const Title = styled(BodyTitle)`
  white-space: nowrap;
`;

const DrawerBody = styled.div`
  width: 100%;
  height: fit-content;
  border-top: 1px solid #D9D9D9;
  padding: 16px;
  box-sizing: border-box;
  position: relative;

  overflow: scroll;
  ${({ theme }) => (theme.isDefault ? lightScrollbar : darkScrollbar)};

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NoWrapText = styled(BodyText)`
  max-height: 42px;
  // overflow: hidden;
  align-self: flex-start;
`;

const FootText = styled(NoWrapText)`
  color: #697273;
  font-style: Italic;
`;

const SelectText = styled(BodyText)`
  align-self: flex-start;
  margin: 32px 0 0 0;
  max-height: 18px;
  // overflow: hidden;
`;


const FormDiv = styled.div`
  width: 100%;
  padding: 0 16px 16px 16px; 
  height: ${({formHeight}) => formHeight};
  // margin: 16px 0 0 0;
`;

const ListingNameDiv = styled.div`
  width: 100%;
  display: flex;
  height: 48px;
  flex-direction: row;
  align-items: center;
  margin: 24px 0 0 0;
  box-sizing: border-box;
  padding: 0 8px 0 0;
`;


const BenchmarkDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const PropertyDiv = styled.div`
  width: 50%;
  display: flex;
  min-height: 88px;
  flex-direction: column;
`;

const PropertyDivInput = styled(PropertyDiv)`
  margin-top: -20px;
`;

const ListingImg = styled.img`
  min-width: 58px;
  min-height: 40px;
  width: 58px;
  height: 40px;
  image-rendering: -webkit-optimize-contrast;
`;

const MinMaxDiv = styled(PropertyDiv)`
  width: 34%;
`;  

const PropertyBenchmarkDiv = styled.div`
  width: 100%;
  display: flex;
  background: #E8E8E8;
  margin: 16px 0 0 0;
  padding: 16px;
  gap: 180px;
`;

const PropertyTitle = styled(BodyTitle)`
  font-size: 18px;
`;


const LabelDiv = styled.div`
  width: fit-content;
  display: flex;
  flex-direction: row;
  margin: 0 0 8px 0;
`;


const ApplyButton = styled(PrimaryButton)`
  position: absolute; 
  bottom: 16px;
  right: 16px;
  width: 120px;
  height: 44px;
  z-index: 2;
  align-self: flex-end;

  ${media.bpxxl} {
    position: sticky;
  };
  

`;

const TooltipDiv = styled.div`
  width: fit-content;
  height: fit-content;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 100;
`;


const ItalicTooltipText = styled(BodyText)`
  font-style: italic;
`;

const BoxText = styled.span`
  border: 2px solid red;
  font-style: initial;
  padding: 4px;
  text-align: center;
`;

const CurrencySelect = styled(Select)`
  width: 24%;
  height: 42px;
  margin: 28px 0 0 0;
`;

const CurrencyEdit = styled(CurrencySelect)`
  margin: 0px 0 0 0;
`;

const SelectLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;


const ListingDrawer = ({listingDrawer, setListingDrawer, updating}) => {

  const [selectCurrency, setSelectCurrency] = useState('USD');
  const [currencyList, setCurrencyList] = useState([]);
  const [onProgress, setOnProgress] = useState(false);

  const [formItems, setFormItems] = useState({
    benchmarkPrice: {value: '', error: false},
    airBnbId: {value: '', error: false},
    vrboId: {value: '', error: false},
    min: '',
    max: '',
  });

  useEffect(() => {

    const fetchCurrencies = async () => {
      const currenciesData = await user.getCurrencies();
      setCurrencyList(currenciesData);
    };

    fetchCurrencies();

  }, []);
  
  const dispatch = useDispatch();

  const currencySymbol = localStorage.getItem("currency");

  useEffect(() => {
    if (listingDrawer.isVisible) {
      setFormItems((prev) => ({
        ...prev,
        benchmarkPrice: {...prev.benchmarkPrice, value : listingDrawer?.benchmarkPrice ? listingDrawer?.benchmarkPrice : '' },
        airBnbId: {...prev.airBnbId, value : listingDrawer?.airBnbId ? listingDrawer?.airBnbId : ''},
        vrboId: {...prev.vrboId, value : listingDrawer?.vrboId ? listingDrawer?.vrboId : ''}
      }));
    }
}, [listingDrawer.isVisible, listingDrawer.benchmarkPrice, listingDrawer.airBnbId,  listingDrawer.vrboId ]);


  const onCloseDrawer = () => {
    setFormItems({
      benchmarkPrice: {value: '', error: false},
      airBnbId: {value: '', error: false},
      vrboId: {value: '', error: false},
      min: '',
      max: '',
    });
    setListingDrawer((prev) => ({...prev, isVisible: false}));
  };

  const onFormChange = useCallback((formKey, {value, error}) => {
    const itemValue = formKey === 'benchmarkPrice' ? Number(value) : value;
    setFormItems((prev) => ({...prev, [formKey]: {value: itemValue, error}}));
  } ,[]);

  const onMaxInputChange = (value) => {
    setFormItems((prev) => ({...prev, max: value}))
  };

  const onMinInputChange = (value) => {
    setFormItems((prev) => ({...prev, min: value}))
  };
  

  const onApply = async() => {

    // setApplyVisible(true);

    try {
      setOnProgress(true) 
      const {benchmarkPrice, airBnbId, min, max, vrboId} = formItems;

      const params = {
        benchmarkPrice: Number(benchmarkPrice.value),
        minPrice: min,
        maxPrice: max,
        airBnbId: airBnbId.value,
        currency: selectCurrency,
      }
      
      const editParms = {
        benchmarkPrice: Number(benchmarkPrice.value),
        airBnbId: airBnbId.value,
        currency: selectCurrency,
      }

      if (vrboId.length) {
        params.vrboId = vrboId.value;
        editParms.vrboId = vrboId.value;
      };

      if (min > max) {
        message.error("min should not be greater than max");
        setOnProgress(false) 
        return;
      }

      const dynamicSetResult = listingDrawer.isEdit ? await markets.updateListingPricing({id: listingDrawer.id, params: editParms}) : await markets.toggleListingPricing({id: listingDrawer.id, params});

      if (dynamicSetResult.status){
        message.success("Silent Dynamic Pricing Updated Succesfully");
        dispatch(getListings());
        onCloseDrawer();
        setOnProgress(false) 
        return;
      }; 

      message.error('Error on Silent Dynamic Pricing update.')
      setOnProgress(false) 

    } catch (e) {
      message.error('Error on Silent Dynamic Pricing update.')
      setOnProgress(false) 
    }


  };


  const disableApply = listingDrawer.isEdit ? (!formItems.benchmarkPrice.value > 0 || !formItems.airBnbId.value?.length || onProgress ) : (!formItems.benchmarkPrice.value > 0 || !formItems.airBnbId.value?.length || !formItems.min > 0 || !formItems.max > 0 || onProgress);

  const handleSelectChange = (value) => {
    setSelectCurrency(value);
  }

  return (
    <ListingDrawerDiv drawerWidth={listingDrawer?.isVisible ? '572px' : '0px'} >
      <ListingDrawerBody>
        <TitleDiv>
          <Title>
            Silent Dynamic Pricing Setup 
          </Title>
          <CloseButton onClick={onCloseDrawer}>
            <BodyText>
              X
            </BodyText>
          </CloseButton>
        </TitleDiv>
        <DrawerBody>
          <NoWrapText>
            Quibble uses the benchmark price to find the appropriate competitors for this listing.  We will segment the nearby competitors from this range.
          </NoWrapText>
          <SelectText>
            Select the benchmark price you want to apply.
          </SelectText>
          <PropertyBenchmarkDiv>
            <PropertyTitle>
              Property
            </PropertyTitle>
            <PropertyTitle>
              Benchmark Price ({currencySymbol})
            </PropertyTitle>
          </PropertyBenchmarkDiv>
          <FormDiv formHeight={!listingDrawer.isEdit ? "616px" : "490px"}>
            <BenchmarkDiv>
              <PropertyDiv>
                <ListingNameDiv>
                  <ListingImg src={listingDrawer?.thumbnailurl} alt="thumbnail url" />
                  <BodyText margin="0px 0 0 8px">
                    {listingDrawer?.name}
                  </BodyText>
                </ListingNameDiv>
              </PropertyDiv>
              <PropertyDivInput>
                <FormItemInput type="number" formKey="benchmarkPrice" onChangeFunc={onFormChange} value={formItems.benchmarkPrice.value} label='' />
              </PropertyDivInput>
            </BenchmarkDiv>
            <NoWrapText margin="32px 0 0 0">
              Enter the listing ID for the rental platform where you want to set the benchmark price.
            </NoWrapText>
            <FormItemInput 
              formKey="airBnbId" 
              onChangeFunc={onFormChange} 
              value={formItems.airBnbId.value} 
              label={<LabelDiv>Airbnb ID &nbsp; <Tooltip 
                title={
                  <TooltipDiv > 
                    <BodyText> How to find your ID? </BodyText> 
                    <ItalicTooltipText> Ex. https://www.airbnb.com/rooms/<BoxText>123456789012345678</BoxText> </ItalicTooltipText> 
                  </TooltipDiv>}><img src={info} alt='info' /> </Tooltip></LabelDiv>} />
            <FormItemInput 
              formKey="vrboId" 
              onChangeFunc={onFormChange} 
              value={formItems.vrboId.value} 
              label={<LabelDiv>Vrbo ID (Optional) &nbsp; <Tooltip 
                title={
                  <TooltipDiv > 
                    <BodyText> How to find your ID? </BodyText> 
                    <ItalicTooltipText> Ex. https://www.vrbo.com/<BoxText>3012695</BoxText> </ItalicTooltipText> 
                  </TooltipDiv>}><img src={info} alt='info' /> </Tooltip></LabelDiv>} />
              {
                !listingDrawer.isEdit ?
                <Fragment>
                  <SelectText>
                    Set Min/Max Price
                  </SelectText>
                  <BenchmarkDiv>
                    <CurrencySelect value={selectCurrency} onChange={handleSelectChange} options={
                      currencyList.map((data) => (
                        {
                          label:  <SelectLabel key={data} > <CurrencyFlag currency={data} /> <BodyText> {data} </BodyText> </SelectLabel>,
                          value: data,
                        }
                      ))
                      } />
                    <MinMaxDiv>
                      <BodyText margin="8px 0 10px 0">
                        Minimum ({currencySymbol})
                      </BodyText>
                      <IncrementDecrementInput inputValue={formItems.min} onInputChange={onMinInputChange}/>
                    </MinMaxDiv>
                    <MinMaxDiv>
                      <BodyText margin="8px 0 10px 0">
                        Maximum ({currencySymbol})
                      </BodyText>
                      <IncrementDecrementInput inputValue={formItems.max} onInputChange={onMaxInputChange}/>
                    </MinMaxDiv>
                  </BenchmarkDiv>
                  <FootText>
                    After this initial setup, you can access the property price adjustment screen to set  your Min/Max Prices on a monthly basis.
                  </FootText>
                </Fragment>
                :
                <Fragment>
                  <BodyText margin="24px 0 12px 0">
                    Currency:
                  </BodyText>
                  <CurrencyEdit value={selectCurrency} onChange={handleSelectChange} options={[
                      {label : <SelectLabel> <CurrencyFlag currency='USD' /> <BodyText> USD </BodyText> </SelectLabel>   ,  value: 'USD'},
                      {label : <SelectLabel> <CurrencyFlag currency='MXN' /> <BodyText> MXN </BodyText> </SelectLabel>   ,  value: 'MXN'},
                      {label : <SelectLabel> <CurrencyFlag currency='CNY' /> <BodyText> CNY </BodyText> </SelectLabel>   ,  value: 'CNY'},
                      {label : <SelectLabel> <CurrencyFlag currency='EUR' /> <BodyText> EUR </BodyText> </SelectLabel>   ,  value: 'EUR'},
                      {label : <SelectLabel> <CurrencyFlag currency='CAD' /> <BodyText> CAD </BodyText> </SelectLabel>   ,  value: 'CAD'},
                      {label : <SelectLabel> <CurrencyFlag currency='JPY' /> <BodyText> JPY </BodyText> </SelectLabel>   ,  value: 'JPY'},
                      {label : <SelectLabel> <CurrencyFlag currency='GBP' /> <BodyText> GBP </BodyText> </SelectLabel>   ,  value: 'GBP'},
                      {label : <SelectLabel> <CurrencyFlag currency='NZD' /> <BodyText> NZD </BodyText> </SelectLabel>   ,  value: 'NZD'},
                      {label : <SelectLabel> <CurrencyFlag currency='AUD' /> <BodyText> AUD </BodyText> </SelectLabel>   ,  value: 'AUD'},
                   ]} />
                   <br />
                   <br/>
                  <FootText>
                    You can set now your Min/Max Prices at the price adjustment screen.
                  </FootText>
                </Fragment>
              } 
          </FormDiv>
        </DrawerBody>
        <ApplyButton disabled={disableApply || updating } onClick={onApply}>{listingDrawer.isEdit ? 'Update' : 'Apply'}</ApplyButton>
      </ListingDrawerBody>
    </ListingDrawerDiv>
  )
}

export default ListingDrawer;