import styled from "styled-components";

export const TableRowBody = styled.div`
  width: 100%;
  height: 80px;
  max-height: ${({maxHeight}) => maxHeight || '48px'};
  transition: all 0.1s linear;
  display: flex;
  flex-direction: row;
  color: ${({theme}) => theme.bodyFg};
  background: ${({background}) => background};
  position: relative;
  &.active {
    color: ${({theme}) => theme.quibbleFontGrey};
    background: ${({theme}) => theme.tableActiveBlue};
  };


  ${({RowCSS}) => RowCSS};
`; 


export const TableRowDiv = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 16px 12px;
  min-width: ${({width}) => width};
  text-align:  ${({align}) => align};
  font-family: Commissioner;
  font-size: ${({theme}) => theme.s3};
  font-weight: 400;
  border-bottom: 1px solid ${({theme}) => theme.quibbleHoverGrey};
`;


export const TableRowCheckBox = styled(TableRowDiv)`
  min-width: 20px;
  max-width: 32px;
  padding: 16px 0px 16px 12px;
`;
