import React, { useState } from 'react';
import styled from 'styled-components';
import FormItemInput from 'components/ReusableComponents/Form/FormItemInput';
import { PrimaryButton } from 'components/ReusableComponents/Button/Button';

const EscapiaDiv = styled.div`
  width: 460px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const EscapiaButton = styled(PrimaryButton)`
  width: 460px;
  margin: 24px 0px;
`;

const Escapia = ({onSubmitForm}) => {

  const [escapiaParams, setEscapiaParams] = useState({
    propertyManagerId: ''
  });

  const onFormChange = (formKey, {value}) => {
    setEscapiaParams((prev) => ({
      ...prev,
      [formKey]: value,
    }));
  };

  const onTokenSubmit = () => {
    const values = {
      ...escapiaParams,
    }
    onSubmitForm(values)
  };

  return (
    <EscapiaDiv>
      <FormItemInput formKey="Property Manager ID" value={escapiaParams.propertyManagerId}  label="Property Manager ID" onChangeFunc={onFormChange} />
      <EscapiaButton disabled={!escapiaParams.propertyManagerId.length} onClick={onTokenSubmit}> Submit </EscapiaButton>
    </EscapiaDiv>
  )
}

export default Escapia;
