import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import OptimizeTable from './components/OptimizeTable';
import OptimizeDrawer from './components/OptimizeDrawer';
import { LayoutBody } from 'components/ReusableComponents/Layout/LayoutBody';
import { lightScrollbar, darkScrollbar } from 'components/ReusableComponents/Scrollbar/Scrollbar';
import { currentDate } from '../../../constants/GlobalConstant';
import { property } from '../../../services/property';
import { message } from 'antd';
import { PriceAdjustContext } from '../PriceAdjustment';
import { isEmpty, isUndefined } from 'lodash';
import { getMarketPricing, getMarketPricingRules, getPortfolioPricing, getPortfolioPricingRules, getPropertyPricing, getPropertyPricingRules } from '../../../appRedux/actions';
import OptimizeGraph from './components/OptimizeGraph';
import { getMultiplierLogic } from '../components/utils';
import { dayIndex } from './components/subComponents/OptimizeDrawer/MinMax';


const OptimizeBody = styled(LayoutBody)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 96%;
  position: relative;
  padding: 0px;
`;

const OptimizeTableDrawerBody = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1774px;
  height: 100%;
  overflow: visible;
  ${({ theme }) => (theme.isDefault ? lightScrollbar : darkScrollbar)};
`;


const Optimize = ({setMonthYear}) => {
  const {isProperty, selectedDates, adjustmentMenu, setSelectedDates, activeMarket, monthYear, activeProperty, priceAdjust,showPriceAdjustGraph, setPriceAdjust, minMaxPrice, stayRules, setMinMaxPrice, setStayRules, farOutPrice} = useContext(PriceAdjustContext);
  
  const {
    propertyPricing,
    marketPricing,
    portfolioPricing,
    isFetchingPropertyPricing,
    isFetchingMarketPricing,
    isFetchingPortfolioPricing } = useSelector(({ property, markets }) => ({...property, ...markets}));

  const pricingObject = {
    property: propertyPricing,
    market: marketPricing,
    portfolio: portfolioPricing,
  }

  const pricingLoading = {
    property: isFetchingPropertyPricing,
    market: isFetchingMarketPricing,
    portfolio: isFetchingPortfolioPricing,
  }

  const pricingData = pricingObject[adjustmentMenu];
  const isFetchingData = pricingLoading[adjustmentMenu];

  const [showToTablePricing, setShowToTablePricing] = useState([]);
  const [savingData, setSavingData] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const filteredpricingData = pricingData.filter((value) => {
      return !(new Date(value.date) < new Date(currentDate))
    });
    setShowToTablePricing(filteredpricingData);
  },[pricingData]);


  const setSelectedDaysFunc = useCallback((value) => {
    if(Array.isArray(value)) {
      setSelectedDates(value);
      return;
    }; 

    setSelectedDates((prev) => {
      if (prev.includes(value)) {
        return prev.filter((data) => data !== value);
      }
      return [...prev, value]
    });
  }, [setSelectedDates]);

  const onAppyChanges = useCallback((value, mode) => {
      const selectedCopy = [...selectedDates];
      selectedCopy.forEach((currentData) => {
        const dateIndex = showToTablePricing.findIndex(data => data.date === currentData);
        if (mode === "multiplier") {
          if (adjustmentMenu === 'portfolio') {
            setPriceAdjust((prev) => ({...prev, portfolio: { ...prev.portfolio,
              [currentData]: {
                ...prev.portfolio?.[currentData],
                multiplier: getMultiplierLogic(!isUndefined(prev.portfolio[currentData]?.multiplier) ? (prev.portfolio[currentData]?.multiplier + value) : (value  + showToTablePricing[dateIndex].multiplier)),
              }}}))
          };

          if (adjustmentMenu === 'market') {
            setPriceAdjust((prev) => ({...prev, market: {...prev.market, [activeMarket]: {...prev.market?.[activeMarket],
              [currentData] : {
                ...prev.market?.[activeMarket]?.[currentData],
                multiplier: getMultiplierLogic(!isUndefined(prev.market?.[activeMarket]?.[currentData]?.multiplier) ? (prev.market?.[activeMarket]?.[currentData]?.multiplier + value) : ( value  + showToTablePricing[dateIndex].multiplier)),
              }}}}));
          };
          if (adjustmentMenu === 'property') {
            setPriceAdjust((prev) => ({...prev, property: {...prev.property, [activeProperty]: {...prev.property?.[activeProperty],
              [currentData]: {
                ...prev.property?.[activeProperty]?.[currentData],
                multiplier: getMultiplierLogic(!isUndefined(prev.property?.[activeProperty]?.[currentData]?.multiplier) ? (prev.property?.[activeProperty]?.[currentData]?.multiplier + value) : (value + showToTablePricing[dateIndex].multiplier) ),
              }}}}));
          }
        };

        if (mode === "priceOverride") {
          setPriceAdjust((prev) => ({...prev, property: {...prev.property, [activeProperty]: {...prev.property?.[activeProperty], [currentData]: {...prev.property?.[activeProperty]?.[currentData], override: value }}}}));
        };

        if (mode === "minStayOverride") {
          if (adjustmentMenu === 'portfolio') {
            setPriceAdjust((prev) => ({...prev, portfolio: { ...prev.portfolio, [currentData]: {...prev.portfolio?.[currentData], minStay: value}}}))
          }
          if (adjustmentMenu === 'market') {
            setPriceAdjust((prev) => ({...prev, market: { ...prev.market, [activeMarket]: {...prev.market?.[activeMarket], [currentData] : {...prev.market?.[activeMarket]?.[currentData], minStay: value}}}}));
          }
          if (adjustmentMenu === 'property') {
            setPriceAdjust((prev) => ({...prev, property: {...prev.property, [activeProperty]: {...prev.property?.[activeProperty], [currentData]: {...prev.property?.[activeProperty]?.[currentData], minStay: value}}}}));
          }
        };
      }); 
  }, [selectedDates, adjustmentMenu, activeMarket, activeProperty, setPriceAdjust, showToTablePricing]);

  const onReset = useCallback(() => {

    setPriceAdjust({portfolio: {}, market: {}, property: {}});
    setMinMaxPrice({});
    setStayRules({ portfolio: {}, market: [], property: [] });
    setSelectedDates([]);

  }, [setSelectedDates, setPriceAdjust, setMinMaxPrice,setStayRules]);

  const onSave = useCallback(async (withSend) => {
    try {
      setSavingData(true);
      const loadingMessage = withSend ? 'Sending Data to PMS.' : 'Saving rules.';
  
      const priceAdjustPortfolio = priceAdjust.portfolio;
      const portfolioCalendar = Object.keys(priceAdjustPortfolio).map((date) => {
        const returnObject = {
          startDate: date,
          endDate: date,
        };
  
        if (priceAdjustPortfolio[date].multiplier !== null) {
          returnObject.multiplier = priceAdjustPortfolio[date].multiplier;
        }
  
        if (priceAdjustPortfolio[date].minStay !== null) {
          returnObject.minStay = priceAdjustPortfolio[date].minStay;
        }
  
        return returnObject;
      });


      const priceAdjustMarketWhole = priceAdjust.market;
      const priceAdjustMarketList = Object.keys(priceAdjustMarketWhole).map((market) => {
        const priceAdjustMarket = priceAdjustMarketWhole[market];
        return {
          name: market,
          calendar: Object.keys(priceAdjustMarket).map((date) => {
            const returnObject = {
              startDate: date,
              endDate: date,
            };
  
            if (priceAdjustMarket[date].multiplier !== null) {
              returnObject.multiplier = priceAdjustMarket[date].multiplier;
            }
  
            if (priceAdjustMarket[date].minStay !== null) {
              returnObject.minStay = priceAdjustMarket[date].minStay;
            }
  
            return returnObject;
          }),
        };
      });
  
      const stayRulesMarket = stayRules['market'];
      Object.entries(stayRulesMarket).forEach(([key, data]) => {
        const marketIndex = priceAdjustMarketList.findIndex(market => market.name === key);
  
        if (marketIndex !== -1) {
          if (!isEmpty(data.stays)) {
            priceAdjustMarketList[marketIndex].stays = data.stays;
          }
        } else {
          priceAdjustMarketList.push({
            name: key,
            stays: !isEmpty(data.stays) ? data.stays : undefined,
          });
        }
      });
  
      const priceAdjustPropertyWhole = priceAdjust.property;
      const priceAdjustPropertyList = Object.keys(priceAdjustPropertyWhole).map((property) => {
        const priceAdjustProperty = priceAdjustPropertyWhole[property];
        return {
          id: property,
          calendar: Object.keys(priceAdjustProperty).map((date) => {
            const returnObject = {
              startDate: date,
              endDate: date,
            };
  
            if (priceAdjustProperty[date].multiplier !== null) {
              returnObject.multiplier = priceAdjustProperty[date].multiplier;
            }
  
            if (priceAdjustProperty[date].minStay !== null) {
              returnObject.minStay = priceAdjustProperty[date].minStay;
            }
  
            if (priceAdjustProperty[date].override !== null) {
              returnObject.override = priceAdjustProperty[date].override;
            }
  
            return returnObject;
          }),
        };
      });
  
      const stayRulesProperty = stayRules['property'];
      Object.entries(stayRulesProperty).forEach(([key, data]) => {
        const propertyIndex = priceAdjustPropertyList.findIndex(property => property.id === key);
  
        if (propertyIndex !== -1) {
          if (!isEmpty(data.stays)) {
            priceAdjustPropertyList[propertyIndex].stays = data.stays;
          }
        } else {
          priceAdjustPropertyList.push({
            id: key,
            stays: !isEmpty(data.stays) ? data.stays : undefined,
          });
        }
      });
  
      //add min max
      Object.keys(minMaxPrice).forEach((property) => {
        let propertyIndex = priceAdjustPropertyList.findIndex((data) => data.id === property);

        if (propertyIndex === -1) {
          priceAdjustPropertyList.push({
            id: property,
            limits: [],
          });
          propertyIndex = priceAdjustPropertyList.length - 1;
        }

        const limits = [];

        Object.keys(minMaxPrice[property]).forEach((monthKey) => {
          const monthLimits = minMaxPrice[property][monthKey];

          if (!monthKey.includes(',')) {
            if (monthLimits?.min !== undefined || monthLimits?.max !== undefined) {
              const monthLimitData = { month: Number(monthKey) };
              if (monthLimits.min !== undefined) monthLimitData.min = monthLimits.min;
              if (monthLimits.max !== undefined) monthLimitData.max = monthLimits.max;
              limits.push(monthLimitData);
            }
          } else {
            const [month, day] = monthKey.split(',');
            if (monthLimits?.min !== undefined || monthLimits?.max !== undefined) {
              const dayLimitData = { month: Number(month), day: Number(day) };
              if (monthLimits.min !== undefined) dayLimitData.min = monthLimits.min;
              if (monthLimits.max !== undefined) dayLimitData.max = monthLimits.max;
              limits.push(dayLimitData);
            }
          }
        });
        
        console.log('limits: ', limits);
        priceAdjustPropertyList[propertyIndex].limits = limits;
      });

      //add far out price
      Object.keys(farOutPrice).forEach((property) => {
        const propertyIndex = priceAdjustPropertyList.findIndex((data) => data.id === property);
        if (propertyIndex !== -1) {
          priceAdjustPropertyList[propertyIndex].faroutStartDay = farOutPrice[property].faroutStartDay;
          priceAdjustPropertyList[propertyIndex].faroutPrice = farOutPrice[property].faroutPrice;
        } else {
          priceAdjustPropertyList.push({
            id: property,
            faroutStartDay: farOutPrice[property].faroutStartDay,
            faroutPrice: farOutPrice[property].faroutPrice,
          });
        }
      });
  
      const portfolioStays = stayRules?.portfolio?.portfolio?.stays;
  
      const dataParams = {
        portfolio: isEmpty(portfolioCalendar) ? {} : { calendar: portfolioCalendar },
        markets: priceAdjustMarketList,
        properties: priceAdjustPropertyList,
      };
  
      if (!isEmpty(portfolioStays)) {
        dataParams.portfolio.stays = portfolioStays;
      }
  
      const rulesParams = Object.keys(dataParams)
        .filter((level) => !isEmpty(dataParams[level]))
        .reduce((outputData, currentData) => {
          return {
            ...outputData,
            [currentData]: dataParams[currentData],
          };
        }, {});
  
      if (isEmpty(rulesParams)) {
        message.warning('Nothing to update');
        setSavingData(false);
        return;
      }
  
      message.loading(loadingMessage, 20);
  
      const result = await property.applyRules(rulesParams, withSend);
  
      const callReset = () => {
        const month = monthYear.getMonth() + 1;
        const year = monthYear.getFullYear();
  
        if (activeProperty.length) {
          dispatch(getPropertyPricing({ month, year, propertyId: activeProperty }));
          dispatch(getPropertyPricingRules({ params: { listingId: activeProperty } }));
        }
  
        if (activeMarket.length) {
          dispatch(getMarketPricing({ month, year, market: activeMarket }));
          dispatch(getMarketPricingRules({ params: { market: activeMarket } }));
        }
  
        dispatch(getPortfolioPricing({ month, year }));
        dispatch(getPortfolioPricingRules());


        onReset();
      };
  
      if (result.status === 429) {
        message.destroy();
        message.warning(result?.data?.message, 6);
        setSavingData(false);

        callReset();
       
        return;
      }


      if (result.status === 200) {
        message.destroy();
        message.success('Price Optimization saved.', 4);
        setSavingData(false);

        callReset();

        return;
      }
  
      message.error('Price Optimization failed.');
      setSavingData(false);
    } catch (e) {
      message.destroy();
      console.error(e);
      setSavingData(false);
      message.error('Price Optimization error.');
    }
  }, [priceAdjust, minMaxPrice, farOutPrice, stayRules, onReset, monthYear, activeProperty, activeMarket, dispatch]);

  const disabledDates = showToTablePricing.filter((data) => data?.booking?.length).map((data)=>data?.date);

  const setMonthYearVal = useCallback((value) => {
    setMonthYear(value);
  }, [setMonthYear]);

  return (
    <OptimizeBody>
      <OptimizeTableDrawerBody>
        {
          showPriceAdjustGraph && isProperty ?
        <OptimizeGraph propertyPricing={propertyPricing}/> :
        <OptimizeTable
          adjustmentMenu={adjustmentMenu}
          onAppyChanges={onAppyChanges}
          isLoading={isFetchingData}
          showToTablePricing={showToTablePricing}
          setSelectedDaysFunc={setSelectedDaysFunc}
          selectedDates={selectedDates}
          disabledDates={disabledDates}
          />   }
        <OptimizeDrawer
          adjustmentMenu={adjustmentMenu}
          isFetchingPropertyPricing={isFetchingData}
          onReset={onReset} onSave={onSave}
          savingData={savingData}
          onAppyChanges={onAppyChanges}
          setSelectedDaysFunc={setSelectedDaysFunc}
          selectedDates={selectedDates}
          monthYear={monthYear}
          setMonthYear={setMonthYearVal}
          disabledDates={disabledDates}/>
      </OptimizeTableDrawerBody>
    </OptimizeBody>
  )
};

export default Optimize;
